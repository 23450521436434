import React from "react"
import Helmet from "react-helmet"

export const Seo = () => {
  return (
    <Helmet>
      <title>Is it worth optimizing?</title>
    </Helmet>
  )
}
