export const determinePeriodMultiplier = value => {
  switch (value) {
    case 'years':
      return 365
    case 'months':
      return 30
    case 'weeks':
      return 7
    case 'days':
      return 1
    default:
      console.log('Incorrect periodTimeFrame')
      return 1
  }
}

export const determineMultiplier = value => {
  switch (value) {
    case 'seconds':
      return 1
    case 'minutes':
      return 60
    case 'hours':
      return 3600
    case 'days':
      return 84600
    default:
      console.log('Incorrect value for multiplier')
      return 1
  }
}

export const determineFrequencyMultiplier = value => {
  switch (value) {
    case 'day':
      return 1
    case 'week':
      return 1 / 7
    case 'month':
      return 1 / 30
    case 'year':
      return 1 / 365
    default:
      console.log('Incorrect frequencyTimeFrame')
      return 1
  }
}

export const formatValue = seconds => {
  if (seconds < 60) {
    return (
      seconds.toFixed(1) +
      (parseInt(seconds.toFixed(0)) === 1 ? ' second' : ' seconds')
    )
  }
  const minutes = seconds / 60
  if (minutes < 60) {
    return (
      minutes.toFixed(1) +
      (parseInt(minutes.toFixed(0)) === 1 ? ' minute' : ' minutes')
    )
  }
  const hours = minutes / 60
  if (hours < 24) {
    return (
      hours.toFixed(1) + (parseInt(hours.toFixed(0)) === 1 ? ' hour' : ' hours')
    )
  }
  const days = hours / 24
  if (days < 30) {
    return (
      days.toFixed(1) + (parseInt(days.toFixed(0)) === 1 ? ' day' : ' days')
    )
  }
  const weeks = days / 7
  if (weeks < 4) {
    return (
      weeks.toFixed(1) + (parseInt(weeks.toFixed(0)) === 1 ? ' week' : ' weeks')
    )
  }
  const months = weeks / 4
  return (
    months.toFixed(1) +
    (parseInt(months.toFixed(0)) === 1 ? ' month' : ' months')
  )
}

export const finalTimeSaved = (
  frequencyNumber,
  frequencyTimeFrame,
  savingsNumber,
  savingsTimeFrame,
  periodNumber,
  periodTimeFrame
) => {
  const timeSavedInSeconds =
    savingsNumber * determineMultiplier(savingsTimeFrame)
  const timeSavedOverFrequency =
    timeSavedInSeconds *
    frequencyNumber *
    determineFrequencyMultiplier(frequencyTimeFrame)
  const finalSavedTime =
    timeSavedOverFrequency *
    periodNumber *
    determinePeriodMultiplier(periodTimeFrame)
  return finalSavedTime
}
