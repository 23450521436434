import React, { Component } from 'react'

class Input extends Component {
  inputRef = React.createRef()

  componentDidMount = () => {
    this.inputRef.current.style.width =
      this.props.value.toString().length + 1 + 'ch'
  }

  componentDidUpdate = () => {
    this.inputRef.current.style.width =
      this.props.value.toString().length + 1 + 'ch'
  }

  render() {
    const { onChange, value, name, label } = this.props
    return (
      <input
      className="pr fs2 bn bct"
      style={{top: '1px', marginRight: '-1ch'}}
        onChange={e => onChange(e.target.value)}
        value={value}
        type="number"
        ref={this.inputRef}
        name={name}
        aria-label={label}
      />
    )
  }
}

export default Input
