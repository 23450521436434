import React, { useState } from 'react'

import Input from './Input'
import Select from './Select'
import Results from './Results'

import { finalTimeSaved } from './helperFunctions'

const LabelText = ({children}) => {
  return <span className="fs2">{children}</span>
}

const Section = ({children}) => {
return <div
className="mab4 f1"
>{children}</div>
}

const Text = ({children}) => {
  return <div className="fs1 ls0">{children}</div>
}

export const Calculator =() =>  {
  const [frequencyNumber, setFrequencyNumber] = useState(10)
  const [frequencyTimeFrame, setFrequencyTimeFrame] = useState('day')
  const [savingsNumber, setSavingsNumber] = useState(5)
  const [savingsTimeFrame, setSavingsTimeFrame] = useState('seconds')
  const [periodNumber, setPeriodNumber] = useState(1)
  const [periodTimeFrame, setPeriodTimeFrame] = useState('years')
  const [timeValue, setTimeValue] = useState(20)

   return (
      <>
        <h1 className="fs2 fwn tac ls-1 pat1 pab5">
          How long can you work on making a routine task more efficient before
          you're spending more time than you save?
          <Text>
            Based on an{' '}
            <a
              href="https://xkcd.com/1205/"
              target="_blank"
              rel="noreferrer noopener"
            >
              xkcd comic
            </a>
          </Text>
        </h1>
        <div className="df fdr">
          <div className="df fdc f1">
          
            <Section>
              <Text>How much time can you save?</Text>
              <Input
                onChange={setSavingsNumber}
                value={savingsNumber}
                name="savingsNumber"
                label="Write the number"
              />
              <LabelText> </LabelText>
              <Select
                value={savingsTimeFrame}
                onChange={setSavingsTimeFrame}
                name="savingsTimeFrame"
                label="Select the time frame"
              >
                <option value="seconds">
                  {parseInt(savingsNumber) === 1 ? 'second' : 'seconds'}
                </option>
                <option value="minutes">
                  {parseInt(savingsNumber) === 1 ? 'minute' : 'minutes'}
                </option>
                <option value="hours">
                  {parseInt(savingsNumber) === 1 ? 'hour' : 'hours'}
                </option>
                <option value="days">
                  {savingsNumber === 1 ? 'day' : 'days'}
                </option>
              </Select>
            </Section>
            <Section>
              <Text>How often you do the task?</Text>

              <Input
                value={frequencyNumber}
                onChange={setFrequencyNumber}
                name="frequencyNumber"
                label="Write the number"
              />
              <LabelText>
                {parseInt(frequencyNumber) === 1 ? ' time' : ' times'} a{' '}
              </LabelText>
              <Select
                value={frequencyTimeFrame}
                onChange={setFrequencyTimeFrame}
                name="frequencyTimeFrame"
                label="Select the time frame"
              >
                <option value="day">day</option>
                <option value="week">week</option>
                <option value="month">month</option>
                <option value="year">year</option>
              </Select>
            </Section>
            <Section>
              <Text>How much is your time worth?</Text>
              <LabelText>$</LabelText>
              <Input
                value={timeValue}
                onChange={setTimeValue}
                name="timeValue"
                label="How much is your time worth?"
              />
              <LabelText>/hour</LabelText>
            </Section>
            <Section>
              <Text>
                Over how long of a time frame you want to know what your savings
                could be?
              </Text>
              <Input
                value={periodNumber}
                onChange={setPeriodNumber}
                name="periodNumber"
                label="Write the number"
              />
              <LabelText> </LabelText>
              <Select
                value={periodTimeFrame}
                onChange={setPeriodTimeFrame}
                name="periodTimeFrame"
                label="Select the time frame"
              >
                <option value="days">days</option>
                <option value="weeks">weeks</option>
                <option value="months">months</option>
   <option value="years">{periodNumber === 1 ? 'year' : 'years'}</option>
              </Select>
            </Section>
          </div>
         <div className="df fdc f1 jcc aic" >
            <Results
              finalTimeSaved={finalTimeSaved(
                frequencyNumber,
                frequencyTimeFrame,
                savingsNumber,
                savingsTimeFrame,
                periodNumber,
                periodTimeFrame
              )}
              timeValue={timeValue}
            />
          </div>
        </div>
      </>
    )
}

export default Calculator
