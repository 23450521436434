import React from 'react'

const Select = ({ onChange, value, name, label, children }) => {
  return (
    <select
    className="fs2 bn"
      onChange={e => onChange(e.target.value)}
      value={value}
      name={name}
      aria-label={label}
    >
      {children}
    </select>
  )
}

export default Select
