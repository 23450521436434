import React from 'react'

const Ideas = () => {
  return (
    <div className="ideasContainer mat5">
      <h2 className="fs2 fwn lp-1 mat5 tac">Ideas for optimization</h2>
      <h3 className="fs15 fwn mat5">Keyboard shortcuts</h3>
      <p className="fs1">
        There's keyboard shortcut's that anyone could benefit from. You don't
        have to be a programmer or a video editor to save time with shortcuts.
        Even something as simple as Ctrl + Backspace to delete a whole word at
        once will add up over time.
      </p >
      <h3 className="fs15 fwn mat5">Snippets</h3>
      <p  className="fs1">
        Using snippets when coding can save a lot of time. I have a snippet for
        every import I commonly use and also for things like creating a skeleton
        of a React component
      </p >
      <h3 className="fs15 fwn mat5">Extensions</h3>
      <p  className="fs1">
        Of course this depends heavily on which software you work in. There's
        Emmet, which is a well-known extension, but my favorite is Jumpy, which
        I can use to quickly jump to a different place in the text. Vim has a
        lot of "motions" that you can use for the purpose of jumping around
        quickly in the text but Jumpy provides a single quick shortcut to
        achieve 90% of the motions you could want.
      </p>
      <h3 className="fs15 fwn mat5">More powerful computer</h3>
      <p  className="fs1">
        Yes, even something as radical as buying a new computer makes sense. The
        math checks out. You have to load something literally hundreds of times
        a day and savings of couple seconds each time adds up quickly.
      </p>
      <p  className="fs1">
        An average computer should last you at least 3 years. Over that period,
        1 second savings 100 times a day at $30 an hour gives you $900 to spend.
        Can you get a better computer that achieves that saving for that amount
        of money? I know that I could but if you're already on the latest
        generation you might not.
      </p>
    </div>
  )
}

export default Ideas
