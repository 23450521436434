import React from 'react'

import { formatValue } from './helperFunctions'

const Results = ({ finalTimeSaved, timeValue }) => {
  return (
    <>
      <section className="df fdc jcc aic mat2 mab2 tac">
        <div>You could save</div>
        <div className="fwb fs4">{formatValue(finalTimeSaved)}</div>
        <div>
          Which means you could use that much time to optimize the task before
          you would be spending more time than it's worth
        </div>
      </section>
      <section className="df fdc jcc aic mat2 mab2 tac">
        <div>You could also save</div>
        <div className="fs3 fwb">${formatMoney(finalTimeSaved, timeValue)}</div>
        <div>Which means you could spend that amount to optimize that task</div>
      </section>
    </>
  )
}

export default Results

const formatMoney = (finalTimeSaved, timeValue) => {
  const finalValue = ((finalTimeSaved * timeValue) / 3600)
    .toFixed(2)
    .toString()
    .split('')
  if (finalValue.length >= 7) {
    finalValue.splice(finalValue.length - 6, 0, ' ').join('')
  }
  if (finalValue.length >= 11) {
    finalValue.splice(finalValue.length - 10, 0, ' ').join('')
  }
  return finalValue
}
