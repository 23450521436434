import React from "react"

import Layout from "../components/layout"
import { Calculator } from "../components/Calculator/Calculator"
import Ideas from "../components/Ideas/Ideas"
import { Seo } from "../components/Seo"

const IndexPage = () => (
  <Layout>
    <Seo />
    <Calculator />
    <Ideas />
  </Layout>
)

export default IndexPage
